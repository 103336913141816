<template>
  <div style="height: inherit">

    <!-- Header -->
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">

            <div class="result-toggler">
              <feather-icon
                icon="MenuIcon"
                class="d-none"
                size="21"
                @click="mqShallShowLeftSidebar = true"
              />

            </div>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              class=" text-uppercase"
              block
              @click="$router.push({ name: 'apps-service-offer-add-wizard'})"
            >
              {{ $t('Add Service Offer') }}
            </b-button>

            <div class="view-options d-flex">
              <!-- Per Page -->
              <!--              <b-col>-->
              <!--                <label>Show</label>-->
              <!--                <v-select-->
              <!--                  v-model="filters.perPage"-->
              <!--                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
              <!--                  :options="filterOptions.perPage"-->
              <!--                  :clearable="false"-->
              <!--                  class="per-page-selector d-inline-block mx-50"-->
              <!--                />-->
              <!--                <label>entries</label>-->
              <!--              </b-col>-->
              <!-- Sort Button -->
              <!--              <b-dropdown-->
              <!--                v-ripple.400="'rgba(113, 102, 240, 0.15)'"-->
              <!--                :text="$t(sortBy.text)"-->
              <!--                right-->
              <!--                variant="outline-primary"-->
              <!--              >-->
              <!--                <b-dropdown-item-->
              <!--                  v-for="sortOption in sortByOptions"-->
              <!--                  :key="sortOption.value"-->
              <!--                  @click="sortBy=sortOption"-->
              <!--                >-->
              <!--                  {{ $t(sortOption.text) }}-->
              <!--                </b-dropdown-item>-->
              <!--              </b-dropdown>-->

              <!-- Item View Radio Button Group  -->
              <b-form-radio-group
                v-model="itemView"
                class="ml-1 list item-view-radio-group"
                buttons
                size="sm"
                button-variant="outline-primary"
              >
                <b-form-radio
                  v-for="option in itemViewOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  <feather-icon
                    :icon="option.icon"
                    size="18"
                  />
                </b-form-radio>
              </b-form-radio-group>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- TOP Sidebar -->

    <top-filter-sidebar
      :filters="filters"
      :filter-options="filterOptions"
      :results-total="totalUsers"
    />
    <!-- Overlay -->
    <b-overlay
      :show="submittingFetch"
      rounded
      opacity="0.6"
    >
      <!-- Users -->
      <section v-if="itemView==='map-view'">
        <b-card
          no-body
          class="my-2 p-1"
        >
          <providers-list-map-view
            :data="users"
            :filters="filters"
          />
        </b-card>

      </section>
      <section
        v-else
        :class="itemView"
      >

        <offer-card
          v-for="offer in users"
          :key="offer.id"
          :offer="offer"
          :item-view="itemView"
        >
          <!--        <b-card-->
          <!--          v-for="user in users"-->
          <!--          :key="user.id"-->
          <!--          class="ecommerce-card"-->
          <!--          no-body-->
          <!--        >-->
          <!--          <div class="item-img justify-content-center">-->
          <!--            <ProfileAvatar-->
          <!--              :user="user"-->
          <!--            />-->
          <!--          </div>-->

          <!--          &lt;!&ndash; Details &ndash;&gt;-->
          <!--          <b-card-body>-->
          <!--            <div class="d-flex justify-content-between  align-items-center">-->
          <!--              <profile-languages :user="user" />-->
          <!--              <profile-rating />-->
          <!--            </div>-->

          <!--            <b-card-text class="item-description">-->
          <!--              <h4 :class="itemView==='grid-view'?'text-center':''">-->
          <!--                <profile-name :user="user" />-->
          <!--              </h4>-->
          <!--              <profile-service-offers-->
          <!--                :user="user"-->
          <!--                :short="true"-->
          <!--              />-->
          <!--            </b-card-text>-->
          <!--          </b-card-body>-->

          <!--          &lt;!&ndash; Product Actions &ndash;&gt;-->
          <!--          <div class="item-options text-center ">-->
          <!--            <div class="item-wrapper" />-->
          <!--            &lt;!&ndash;            <b-button&ndash;&gt;-->
          <!--            &lt;!&ndash;              variant="light"&ndash;&gt;-->
          <!--            &lt;!&ndash;              tag="a"&ndash;&gt;-->
          <!--            &lt;!&ndash;              class="btn-wishlist"&ndash;&gt;-->
          <!--            &lt;!&ndash;              @click="toggleProductInWishlist(user)"&ndash;&gt;-->
          <!--            &lt;!&ndash;            >&ndash;&gt;-->
          <!--            &lt;!&ndash;              <feather-icon&ndash;&gt;-->
          <!--            &lt;!&ndash;                icon="HeartIcon"&ndash;&gt;-->
          <!--            &lt;!&ndash;                class="mr-50"&ndash;&gt;-->
          <!--            &lt;!&ndash;                :class="{'text-danger': user.isInWishlist}"&ndash;&gt;-->
          <!--            &lt;!&ndash;              />&ndash;&gt;-->
          <!--            &lt;!&ndash;              <span>Wishlist</span>&ndash;&gt;-->
          <!--            &lt;!&ndash;            </b-button>&ndash;&gt;-->
          <!--            <b-button-->
          <!--              variant="primary"-->
          <!--              tag="a"-->
          <!--              class="btn-cart"-->
          <!--              @click="handleUserViewActionClick(user)"-->
          <!--            >-->
          <!--              <feather-icon-->
          <!--                icon="FileTextIcon"-->
          <!--                class="mr-50"-->
          <!--              />-->
          <!--              <span>{{ $t('Read more') }}</span>-->
          <!--            </b-button>-->

          <!--          </div>-->
          <!--        </b-card>-->
        </offer-card>
      </section>
      <!-- Pagination -->
      <section v-if="itemView!=='map-view' && totalUsers">
        <b-row>
          <b-col cols="12">
            <b-pagination
              v-model="filters.page"
              :total-rows="totalUsers"
              :per-page="filters.perPage"
              first-number
              align="center"
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </section>
      <template #overlay>
        <b-spinner
          type="grow"
          label="Loading..."
        />
      </template>
    </b-overlay>
    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-detached-left">
      <left-filter-sidebar
        :filters="filters"
        :filter-options="filterOptions"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
      />
    </portal>
  </div>
</template>

<script>
import {
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BCard,
  BPagination,
  BOverlay,
  BButton,
  BSpinner,

} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { watch } from '@vue/composition-api'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import { defineAbilityForCurrentUser } from '@/libs/acl/defineAbility'
import { getPostStatusProperty, getUserAvatarText } from '@/mixins/functions'
import { usePointsUi } from '@/views/apps/service/usePoints'
import { formatDateTime } from '@/filters/dateTime'
import { serviceOptions } from '@/mixins/options'
import ProfileLanguages from '@/views/pages/profile/ProfileLanguages.vue'
import ProfileAvatar from '@/views/pages/profile/ProfileAvatar.vue'
import ProfileName from '@/views/pages/profile/ProfileName.vue'
import ProfileRating from '@/views/pages/profile/ProfileRating.vue'
import ProfileServiceOffers from '@/views/pages/profile/ProfileServiceOffers.vue'
import { useUserUi } from '@/views/apps/user/useUser'
import ProvidersListMapView from '@/views/apps/service/offer/list/ListMapView.vue'
import TopFilterSidebar from '@/views/apps/service/offer/list/ListTopFiltersSidebar.vue'
import LeftFilterSidebar from '@/views/apps/service/offer/list/ListLeftFilterSidebar.vue'
import OfferCard from '@/views/apps/service/offer/details/OfferCard.vue'
import { useFiltersSortingAndPagination, useUi, useRemoteData } from './useProvidersList'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BCard,
    BPagination,
    BOverlay,
    BButton,
    BSpinner,

    // SFC
    LeftFilterSidebar,
    TopFilterSidebar,

    ProfileName,
    ProfileAvatar,
    ProfileLanguages,
    ProfileServiceOffers,
    ProfileRating,
    ProvidersListMapView,
    OfferCard,
  },
  filters: {
    formatDateTime,
  },
  mixins: [getPostStatusProperty, getUserAvatarText],

  setup() {
    const {
      handleUserViewActionClick,
    } = useUserUi()

    const { getPurposeTitle, getTypeTitle } = serviceOptions
    const {
      filters, filterOptions, sortBy, sortByOptions,
    } = useFiltersSortingAndPagination()

    const {
      handleCartActionClick,
      toggleProductInWishlist,
      handleEditRequestActionClick,
      resolvePointTypeBadgeVariant,
      getFirstGalleryImage,
      defaultImage,
      getOwnerProperty,
    } = usePointsUi()

    const {
      itemView, itemViewOptions, totalUsers,
    } = useUi()

    const ability = defineAbilityForCurrentUser()

    const {
      users, submittingFetch, fetchServiceOffers,
    } = useRemoteData()

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    // Wrapper Function for `fetchServiceOffers` which can be triggered initially and upon changes of filters
    const fetchFilteredServiceOffers = () => {
      // if (filters.value.userId) {
      //   args.user_id = filters.value.userId
      // }
      //
      // if (sortBy.value.value) {
      //   args.sort_by = sortBy.value.value
      // }
      //
      // if (filters.value.postStatuses.length !== 0) {
      //   args.post_statuses = filters.value.postStatuses.join()
      // }
      // args.order = 'desc'

      const args = {
        q: filters.value.q,
        sort_by: 'id',
        order: 'desc',
        page: filters.value.page,
        per_page: filters.value.perPage,
      }

      if (filters.value.geolocationIsActive && filters.value.lat && filters.value.lng && filters.value.locationRadius) {
        args.lat = filters.value.lat
        args.lng = filters.value.lng
        args.location_radius = filters.value.locationRadius / 1000
      }
      if (filters.value.pointPurposes.length !== 0) {
        args.purpose_ids = filters.value.pointPurposes.join()
      }

      if (filters.value.languages.length !== 0) {
        args.langs = filters.value.languages.join()
      }
      fetchServiceOffers(args)
        .then(response => {
          const { total, purposes } = response.data.meta
          users.value = response.data.data
          totalUsers.value = total
          filterOptions.pointPurposes.value = purposes
        })
    }
    if (filters.value.lat && filters.value.lng) {
      fetchFilteredServiceOffers()
    }

    watch([filters, sortBy], () => {
      if (filters.value.pointPurposes.length) {
        fetchFilteredServiceOffers()
      }
    }, {
      deep: true,
    })

    watch([totalUsers], () => {
      if (totalUsers.value) {
        itemView.value = 'grid-view'
      }
    })

    return {
      // useShopFiltersSortingAndPagination
      filters,
      filterOptions,
      sortBy,
      sortByOptions,

      // useShopUi
      itemView,
      itemViewOptions,
      totalUsers,

      // usePointsUi
      toggleProductInWishlist,
      handleCartActionClick,
      handleEditRequestActionClick,
      resolvePointTypeBadgeVariant,

      getFirstGalleryImage,
      defaultImage,
      getPurposeTitle,
      getTypeTitle,

      handleUserViewActionClick,

      getOwnerProperty,

      // useServiceRequestsRemoteData
      submittingFetch,
      users,
      ability,
      fetchServiceOffers,

      // mqShallShowLeftSidebar
      mqShallShowLeftSidebar,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.search-point.form-control{
  height: auto;
}
.actions{
  position: absolute;
  bottom: 0;
  width: 100%;
}
.ecommerce-application .grid-view .ecommerce-card .item-img {
  min-height:0;
}
.ecommerce-application .grid-view .ecommerce-card .item-description {
  white-space: normal;
}
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
@media all and (min-width:768px) and (max-width: 1440px) {
  .ecommerce-application .grid-view{
    grid-template-columns: 1fr 1fr;
  }
}
</style>
